import { IComboBoxOption, IDropdownOption } from '@fluentui/react';
import { userLocales } from '../../config/locale';
import { EN_LOCALE_KEY } from '../../constants';
import { SUPPORTED_LOCALES_MAPPING } from '../../globalization';

export const getSupportedLanguagesComboBoxOptions = (locale: string): IComboBoxOption[] => {
  const isProdEnv = process.env.REACT_APP_ENV === 'production';
  const supportedLocales = SUPPORTED_LOCALES_MAPPING[locale];
  const supportedLanguages: IComboBoxOption[] = [];

  Object.keys(supportedLocales).forEach((localeCode) => {
    supportedLanguages.push({
      key: userLocales[localeCode].toLocaleLowerCase(),
      text: supportedLocales[localeCode],
    });
  });

  supportedLanguages.sort((a, b) => {
    const firstValue = a.text;
    const secondValue = b.text;

    return firstValue.toLowerCase() > secondValue.toLowerCase() ? 1 : -1;
  });

  const filteredLanguages = supportedLanguages.filter((value, index) => value.key === EN_LOCALE_KEY);

  return isProdEnv ? filteredLanguages : supportedLanguages;
};

export const getSupportedLanguagesDropDownOptions = (locale: string): IDropdownOption[] => {
  const isProdEnv = process.env.REACT_APP_ENV === 'production';
  const supportedLocales = getSupportedLocalesMapping(locale);
  const supportedLanguages: IDropdownOption[] = [];

  Object.keys(supportedLocales).forEach((localeCode) => {
    supportedLanguages.push({
      key: userLocales[localeCode].toLocaleLowerCase(),
      text: supportedLocales[localeCode],
    });
  });

  supportedLanguages.sort((a, b) => {
    const firstValue = a.text;
    const secondValue = b.text;

    return firstValue.toLowerCase() > secondValue.toLowerCase() ? 1 : -1;
  });

  const filteredLanguages = supportedLanguages.filter((value, index) => value.key === EN_LOCALE_KEY);

  return isProdEnv ? filteredLanguages : supportedLanguages;
};

export const getSupportedLocalesMapping = (locale?: string | null): string[] => {
  return SUPPORTED_LOCALES_MAPPING[locale ?? EN_LOCALE_KEY] ?? SUPPORTED_LOCALES_MAPPING[EN_LOCALE_KEY];
};
