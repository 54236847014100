import { orchestrator } from 'satcheljs';
import { IUserViewModel } from '..';
import { Log } from '../../../../../../logging/src';
import { getAppStore, onIsAcceptInviteFlowChanged, onUserInvitationCodeChanged } from '../../../../../@data';
import { getSupportedLanguagesDropDownOptions } from '../../../../../@data/utils/supportedLanguages';
import { UNAUTHORIZED_LOCAL_STORAGE_LOCALE } from '../../../../../constants';
import { onLanguageChanged } from '../../../../ad-management/Account/Settings/@data/mutatorActions';
import { getStore } from '../../../../ad-management/Account/Settings/@data/store/store';
import { onPubCenterAADLogin } from '../../../../login/@data';
import { onAcceptInvitePageInitialized, onUserChanged, validateUserInvitationCode } from '../actions';

orchestrator(onAcceptInvitePageInitialized, async (msg) => {
  const { userContext } = getAppStore();
  const { systemPreferences } = getStore().active;

  Log.debug('Initializing acceptInvite page');

  onUserInvitationCodeChanged(msg.invitationCode);
  onLanguageChanged(msg.invitationLocale, UNAUTHORIZED_LOCAL_STORAGE_LOCALE);
  if (!userContext?.token) {
    Log.debug('AcceptInvite page - No user login found, prompting user login');
    onIsAcceptInviteFlowChanged(true);

    onPubCenterAADLogin();
  } else {
    Log.debug(`AcceptInvite page - UserContext is present for ${userContext.email} `);

    // Fetching supported locales and verifying if the locale from the invitation URL is supported, if not default to English
    const localeFromInvitationUrl = systemPreferences?.locale;
    const isInvitationUrlSupported =
      localeFromInvitationUrl &&
      getSupportedLanguagesDropDownOptions(localeFromInvitationUrl ?? 'en-US').find(
        (option) => option.key.toString() === localeFromInvitationUrl
      )
        ? true
        : false;

    const user: IUserViewModel = {
      firstName: userContext.firstName ?? (userContext.name && userContext.name.split(' ')[0]),
      lastName: userContext.lastName ?? (userContext.name && userContext.name.split(' ')[1]),
      userName: userContext.userName ?? userContext.email,
      emailAddress: userContext.email,
      language: isInvitationUrlSupported ? localeFromInvitationUrl : 'en-US',
      contactByEmail: true,
      contactByPhone: false,
    };
    onUserChanged(user);

    // Validate the invitation code asynchronously
    validateUserInvitationCode(msg.invitationCode);
  }
});
