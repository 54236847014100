import { Announced, MessageBar, MessageBarType, PrimaryButton, Spinner, Text, TextField } from '@fluentui/react';
import React, { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useI18n } from '../../../i18n/useI18n';
import { getAppStore } from '../../@data';
import { FormItem } from '../../components/Form';
import { Main } from '../../components/Main/Main';
import { Footer } from '../../layout/Footer/Footer';
import { InternationalizationLayout } from '../../layout/InternationalizationLayout';
import { injectRouterAndIntlWithObserver } from '../../utils';
import { getStore } from '../ad-management/Account/Settings/@data/store/store';
import emailLogo from './emailLogo.svg';
import emailSentLogo from './emailSentLogo.svg';
import messages from './messages';
import { classNames } from './styles';
import { ISupportPageProps } from './types';
import { MAX_WORD_COUNT, useSupportPage } from './useSupportPage';

export const SupportPage = injectRouterAndIntlWithObserver(function SupportPageComponent({ intl, location }: ISupportPageProps) {
  const { formatMessage } = intl;
  const appStore = getAppStore();
  const { publisher, userContext } = appStore;
  const { systemPreferences } = getStore().active;
  const { formattedLocale, countryCode } = useI18n({ systemPreferences, publisher, userContext });
  const {
    emailInput,
    titleInput,
    descriptionInput,
    isFormValid,
    isFormLoading,
    shouldRenderFailureMessage,
    createdTicketCaseNumber,
    onDescriptionChange,
    onFormSubmit,
    setTitleInput,
    setEmailInput,
  } = useSupportPage({ appStore, location, formattedLocale, countryCode });
  const {
    root,
    pageTitle,
    formWrapper,
    emailIcon,
    submitButton,
    errorBannerWrapper,
    formSuccessHead,
    formInputStyles,
    footerWrapper,
    descriptionWordCountWrapper,
    transparentText,
  } = classNames;

  // Ref for focusing on the success message
  const successMessageRef = useRef(null);

  // Use effect to focus on the success message when a ticket is created
  useEffect(() => {
    if (createdTicketCaseNumber && successMessageRef.current) {
      (successMessageRef.current as HTMLElement).focus();
    }
  }, [createdTicketCaseNumber]);

  return (
    <div className={footerWrapper}>
      <InternationalizationLayout />
      <Main className={root}>
        <div className={formWrapper}>
          <h1 className={pageTitle}>{formatMessage(createdTicketCaseNumber ? messages.formCreationSuccessTitle : messages.formTitle)}</h1>
          <img
            className={emailIcon}
            src={createdTicketCaseNumber ? emailSentLogo : emailLogo}
            alt={formatMessage(createdTicketCaseNumber ? messages.formCreationSuccessTitle : messages.formTitle)}
          />
          {!createdTicketCaseNumber ? (
            <>
              <FormItem styles={{ root: formInputStyles }}>
                <TextField
                  id="supportEmailInput"
                  autoComplete="off"
                  disabled={isFormLoading}
                  label={formatMessage(messages.formEmailInputHeader)}
                  required={true}
                  value={emailInput}
                  onChange={(_, value: string) => setEmailInput(value)}
                  placeholder={formatMessage(messages.formEmailInputPlaceholder)}
                  aria-label={formatMessage(messages.formEmailInputPlaceholder)}
                />
              </FormItem>
              <FormItem styles={{ root: formInputStyles }}>
                <TextField
                  id="supportTitleInput"
                  autoComplete="off"
                  disabled={isFormLoading}
                  label={formatMessage(messages.formTitleInputHeader)}
                  required={true}
                  value={titleInput}
                  onChange={(_, value: string) => setTitleInput(value)}
                  placeholder={formatMessage(messages.formTitleInputPlaceholder)}
                  aria-label={formatMessage(messages.formTitleInputPlaceholder)}
                />
              </FormItem>
              <FormItem styles={{ root: formInputStyles }}>
                <TextField
                  id="supportDescriptionInput"
                  autoComplete="off"
                  disabled={isFormLoading}
                  multiline
                  label={formatMessage(messages.formDescriptionInputHeader)}
                  required={true}
                  resizable={false}
                  value={descriptionInput}
                  onChange={(e, value: string) => onDescriptionChange(value)}
                  placeholder={formatMessage(messages.formDescriptionInputPlaceholder)}
                  aria-describedby="description-word-count"
                />
                <Text id="description-word-count" className={descriptionWordCountWrapper} aria-atomic="true">
                  {formatMessage(
                    MAX_WORD_COUNT - descriptionInput.split(/\s+/).filter(Boolean).length === 1
                      ? messages.formDescriptionWordCount
                      : messages.formDescriptionWordsCount,
                    { count: MAX_WORD_COUNT - descriptionInput.split(/\s+/).filter(Boolean).length }
                  )}
                </Text>
              </FormItem>
            </>
          ) : (
            <>
              <div aria-live="assertive">
                <Announced message={formatMessage(messages.formCreationSuccessTitle)} />
                <Announced message={formatMessage(messages.formCreationSuccessDetails, { ticketNumber: createdTicketCaseNumber })} />
              </div>
              <FormItem
                className={formSuccessHead}
                styles={{ root: formInputStyles }}
                tabIndex={-1} // Make the FormItem focusable
                // @ts-ignore
                ref={successMessageRef} // Attach the ref to focus this item
                role="alert" // Ensure screen readers treat this as an alert
              >
                <Text>
                  <span className={transparentText}>
                    <FormattedMessage {...messages.formCreationSuccessTitle} />
                  </span>
                  <FormattedMessage {...messages.formCreationSuccessDetails} values={{ ticketNumber: createdTicketCaseNumber }} />
                </Text>
              </FormItem>
            </>
          )}

          {shouldRenderFailureMessage && (
            <FormItem className={errorBannerWrapper} styles={{ root: formInputStyles }}>
              <MessageBar messageBarType={MessageBarType.error} messageBarIconProps={{ iconName: 'StatusErrorFull' }}>
                <FormattedMessage
                  {...messages.formCreationFailedDetails}
                  values={{
                    msgTitle: <b>{formatMessage(messages.formCreationFailedTitle)}</b>,
                  }}
                />
              </MessageBar>
            </FormItem>
          )}

          {!createdTicketCaseNumber && (
            <PrimaryButton
              ariaLabel={formatMessage(messages.formSubmitButton)}
              className={submitButton}
              disabled={!isFormValid || isFormLoading}
              onClick={onFormSubmit}
              aria-live="assertive"
            >
              {isFormLoading ? (
                <Spinner ariaLabel={formatMessage(messages.formSubmitButtonLoading)} />
              ) : (
                formatMessage(messages.formSubmitButton)
              )}
            </PrimaryButton>
          )}
        </div>
      </Main>
      <Footer />
    </div>
  );
});
